<template>
  <!-- 操作日志 -->
  <van-popup v-model="showOperate" round position="bottom" :closeable="true">
    <div class="operate-wrap">
      <div class="title">操作日志</div>
      <van-steps direction="vertical" :active="-1" inactive-color="#5A5A5A" inactive-icon="circle">
        <van-list v-model="loading" :finished="logFinish" finished-text="没有更多了" @load="load">
          <van-step v-for="(item, index) in operateContent" :key="index">
            <h3>
              <span>{{ item.level == 'campaign' ? '广告系列' : '广告组' }}</span>
              <!-- <span>{{ clientType(item.clientType) }}</span> -->
              <span>{{ operateType(item.operateType) }}</span>
              <span>{{ operateValue(item.operateType, item.oldValue) }}</span>
              <span>-></span>
              <span>{{ operateValue(item.operateType, item.newValue) }}</span>
            </h3>
            <p>{{ item.operateTime.replace('T', ' ') }}</p>
          </van-step>
        </van-list>
      </van-steps>
    </div>
  </van-popup>
</template>

<script>
// import { Dialog, Notify } from 'vant';
export default {
  props: {
    logLoading: {
      type: Boolean,
      default: false,
    },
    logFinish: {
      type: Boolean,
      default: false,
    },
    operateContent: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showOperate: false,
      loading: false,
    };
  },
  watch: {
    logLoading(val) {
      this.loading = val;
    },
  },
  methods: {
    load() {
      this.$emit('load');
    },
    handleOpen() {
      this.showOperate = true;
    },
    handleClose() {
      this.showOperate = false;
    },
    //操作的平台
    clientType(type) {
      switch (type) {
        case 'manual':
          return '人工操作';
        case 'auto':
          return '自动规则';
        case 'timer':
          return '定时操作';
        case 'phone':
          return '手动操作';
        case 'ads':
          return '手动操作';
        default:
          break;
      }
    },
    //操作的内容
    operateType(type) {
      switch (type) {
        case 'status':
          return '状态';
        case 'dailyBudget':
          return '日预算';
        case 'lifetimeBudget':
          return '总预算';
        case 'bidAmount':
          return '竞价';
        case 'creative':
          return '创意';
        case 'audience':
          return '受众';
        default:
          break;
      }
    },
    //操作的值
    operateValue(type, value) {
      if (type === 'status') {
        // return value === 'ACTIVE' || 'ENABLE' ? '开启' : '暂停';
        switch (value) {
          case 'ACTIVE':
            return '开启';
          case 'ENABLE':
            return '开启';
          case 'PAUSED':
            return '暂停';
          default:
          '暂停';
            break;
        }
      } else {
        return value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.operate-wrap {
  max-height: 1400px;
  min-height: 800px;
  padding-top: 30px;
  padding-bottom: 30px;
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    padding: 10px;
  }
  /deep/ .van-step__icon {
    color: #359efe;
  }
  /deep/ .van-step__line {
    top: 29px;
    height: 84%;
    background: #e7e7e7 !important;
  }
  /deep/ .van-step__circle-container {
    top: 24px;
  }
  h3 {
    span {
      margin-right: 10px;
    }
  }
  p {
    font-size: 12px;
    color: #c5c3c2;
  }
}
</style>
