import { selectLogByPage } from '@/api/campaign.js';
import { Notify } from 'vant';
export const operateMethod = {
  data() {
    return {
      operateContent: [], //操作日志数据
      operateTimeStart: '',
      operateTimeEnd: '',
      operateLoading: false,
      paging: {
        page_number: 1,
        page_size: 10,
        total_count: 0,
      },
      logLoading: false,
      logFinish: false,
    };
  },
  methods: {
    getLogLoading(){

    },
    load() {
      //延迟1s执行 保证getDataList执行完毕 能取到总条数
      setTimeout(() => {
        console.log('load------------------');
        this.paging.page_number = this.paging.page_number + 1;
        let totalPage = Math.ceil(this.paging.total_count / this.paging.page_size);
        console.log(this.paging.page_number,totalPage)
        console.log(this.paging.page_number > totalPage)
        if (this.paging.page_number > totalPage) {
          if (this.operateContent.length == 0) {
            this.logLoading = false;
          } else {
            this.logLoading = true;
          }
          return;
        } else {
          this.logLoading = false;
        }
        this.handleOperate();
      }, 1000);
    },
    //点击操作日志按钮
    handleOpenOperateDraw(){
      this.resetLogPage();
      this.handleOperate();
    },
    //处理参数调用操作日志接口
    handleOperate() {
      this.operateLoading = true;
      this.logLoading = true;
      const {
        ad: { platform, campaignId, adAccountId },
      } = this.data;
      // const newName = this.newName;
      this.getOperateTime();

      let params = {
        platform: platform,
        campaignId: campaignId,
        adAccountId: adAccountId,
        adGroupId: this.data.ad.adGroupId,
        level: this.level,
        pageNumber: this.paging.page_number,
        pageSize: this.paging.page_size,
        operateTimeStart: this.operateTimeStart,
        // operateTimeEnd: this.operateTimeEnd,
      };
      selectLogByPage(params)
        .then((res) => {
          this.operateLoading = false;
          this.logLoading = false;
          if (res.code == 0) {
            this.paging = res.data.paging;
            this.operateContent.push(...res.data.contents);
            
            if (this.operateContent.length > 0) {
              this.$refs.operateLogPop.handleOpen();
              if(this.operateContent.length == res.data.paging.total_count){
                this.logFinish = true;
              }
            } else {
              Notify({
                type: 'primary',
                message: '最近七天没有操作日志可以查看哦~',
                duration: 1000,
              });
            }
          }
        })
        .finally(() => {
          this.logLoading = false;
          this.operateLoading = false;
        });
    },
    //重置
    resetLogPage() {
      this.operateContent = [];
      this.paging = {
        page_number: 1,
        page_size: 10,
        total_count: 0,
      };
      this.logLoading = false;
      this.logFinish = false;
    },
    
    //获取7天时间
    getOperateTime() {
      let now = new Date();
      let sevenDaysAgo = new Date();

      sevenDaysAgo.setDate(now.getDate() - 7); // 设置日期为7天前
      this.operateTimeStart = this.formatDate(sevenDaysAgo);
      this.operateTimeEnd = this.formatDate(now);
    },
    formatDate(date) {
      let y = date.getFullYear();
      let M = ('0' + (date.getMonth() + 1)).slice(-2);
      let d = ('0' + date.getDate()).slice(-2);
      let h = ('0' + date.getHours()).slice(-2);
      let m = ('0' + date.getMinutes()).slice(-2);
      let s = ('0' + date.getSeconds()).slice(-2);
      return `${y}-${M}-${d} ${h}:${m}:${s}`;
    },
  },
};
