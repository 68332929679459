import { delAdScheduleOpenTaskMobile, delAdScheduleUpdateBudgetTaskMobile, delUpdateBidTask } from '@/api/campaign';
import { Notify, Dialog } from 'vant';
import openClock from '@/views/components/openClock.vue';
const editOpenClock = {
  data() {
    return {
      openClockShow: false,
      tableKey: null,
    };
  },
  components: { openClock },
  computed: {
    AdScheduleOpenTaskType() {
      return this.initData?.ad?.AdScheduleOpenTaskType;
    },
  },
  methods: {
    // 打开状态
    f_showDialogBudgetClock() {
      this.openClockBudgetShow = true;
      this.$refs.campaignBudget.f_show();
    },
    showSetBidAmount() {
      this.isShowSetBidAmount = true;
    },
    settingComplete(form, type, budgetAmount) {
      let openSchedule = [...this.data.ad.open_schedule];
      if (openSchedule.length > 0) {
        openSchedule.forEach((_item, _index) => {
          if (_item.type == type) {
            openSchedule.splice(_index, 1);
          }
        });
      }
      openSchedule.push({
        type: type,
        timeStr: `${form.handleDate} ${form.taskType}:00`, //form.handleDate+ this.AdScheduleOpenTaskType.filter(v => v.name === form.taskType)[0].desc,
        budget: budgetAmount ? budgetAmount : null,
      });
      let item = { ...this.data };
      item.ad.open_schedule = [...openSchedule];
      this.$emit('emitOpenSchedule', this.index, item);
      this.S4();
    },
    // 取消定时
    cancelSet(type, row, scopeindex, index) {
      let _item = JSON.parse(JSON.stringify(row));
      let msg = '';
      let request = null;
      if (type === 'task') {
        msg = '确定取消定时开启任务吗？';
        request = delAdScheduleOpenTaskMobile;
      } else {
        msg = '确定取消定时开启预算吗？';
        request = delAdScheduleUpdateBudgetTaskMobile;
      }
      Dialog.confirm({
        title: '提示',
        message: msg,
      })
        .then(() => {
          let data = {
            id: this.level == 'campaign' ? row.ad.campaignId : this.level == 'adset' ? row.ad.adGroupId : row.ad.adId,
            level: this.level,
          };
          request(data)
            .then(() => {
              Notify({ type: 'success', message: '取消成功！' });
              _item.ad.open_schedule.splice(index, 1);
              this.$emit('emitOpenSchedule', this.index, _item);
              this.S4();
              console.log(this.data);
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    delUpdateBidTask(id, row, scopeindex, index) {
      Dialog.confirm({
        title: '提示',
        message: '确定取消定时修改出价任务吗？',
      })
        .then(() => {
          delUpdateBidTask({ id }).then((res) => {
            if (res.code == 0) {
              row.ad.open_schedule.splice(index, 1);
              Notify({ type: 'success', message: '取消成功！' });
            }
          });
          // delUpdateBidTask()
        })
        .catch(() => {});
    },
    setTimeSuccess(e) {
      const index = this.data.ad.open_schedule.findIndex((v) => v.id == e.id);
      console.log(index);
      if (index > -1) {
        this.data.ad.open_schedule.splice(index, 1);
      }
      this.data.ad.open_schedule.push(e);
    },
    S4() {
      let stamp = new Date().getTime();
      this.tableKey = (((1 + Math.random()) * stamp) | 0).toString(16);
    },
  },
};
export default editOpenClock;
