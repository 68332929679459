<template>
  <div>
    <van-action-sheet
      v-model="openClockShowData"
      title="定时开启"
      @cancel="onCancel"
      style="height: 800px"
      @click-overlay="onCancel"
    >
      <van-form>
        <van-field
          v-model="form.handleDate"
          label="开启日期"
          placeholder="请选择"
          readonly
          @click="f_chooseDate('myPopupSettingShow')"
        />
        <van-calendar v-if="myPopupSettingShow" v-model="myPopupSettingShow" @confirm="f_sure" />
        <van-field
          v-model="form.taskType"
          label="开启时间"
          placeholder="请选择"
          readonly
          @click="f_chooseDate('timeSettingShow')"
        />
        <van-popup v-model="timeSettingShow" round position="bottom">
          <!-- <van-picker
            title="选择开启时间"
            show-toolbar
            :columns="columns"
            @confirm="f_onConfirm"
            @cancel="timeSettingShow = false"
          /> -->
          <van-datetime-picker
            v-model="form.taskType"
            type="time"
            title="选择时间"
            @confirm="f_onConfirm"
            @cancel="timeSettingShow = false"
          />
        </van-popup>
        <div style="margin: 16px">
          <van-button round block type="info" @click="submitClock" id="editBudget">提交</van-button>
        </div>
      </van-form>
    </van-action-sheet>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import moment from 'moment'; //导入模块
moment.locale('zh-cn'); //设置语言 或 moment.lang('zh-cn');
import { settingAdScheduleOpenTaskMobile } from '@/api/campaign';
import { Notify } from 'vant';
export default {
  props: {
    openClockShow: {
      type: Boolean,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    level: {
      type: String,
      default: 'campaign',
    },
    type: {
      type: String,
      default: 'budget',
    },
    initData: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {},
  computed: {
    ...mapState('user', ['userInfo']),
    columns() {
      let data = this.initData?.ad?.AdScheduleOpenTaskType,
        arr = [];
      if (data && data.length > 0)
        data.map((v) => {
          arr.push({ text: v.desc, value: v.name });
        });
      return arr;
    },
  },
  data() {
    return {
      newValue: null,
      myPopupSettingShow: false,
      timeSettingShow: false,
      minDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      customFieldName: {
        text: 'desc',
        value: 'name',
        children: 'children',
      },
      form: {
        handleDate: null,
        taskType: null,
      },
      openClockShowData: false,
    };
  },
  watch: {
    openClockShow(newVal, oldVal) {
      this.openClockShowData = newVal;
    },
  },
  methods: {
    f_onConfirm() {
      // this.form.taskType = v.value;
      // this.form.taskTypeName = v.text;
      // console.log(this.form);
      this.timeSettingShow = false;
    },
    f_sure(v) {
      this.form.handleDate = moment(v.getTime()).format('YYYY-MM-DD');
      this.myPopupSettingShow = false;
    },
    f_chooseDate(v) {
      this[v] = true;
    },
    onCancel() {
      (this.form = {
        handleDate: null,
        taskType: null,
      }),
        this.$emit('update:openClockShow', false);
    },
    submitClock() {
      if (!this.form.handleDate) {
        return Notify({ type: 'warning', message: '请选择开启日期' });
      }
      if (!this.form.taskType) {
        return Notify({ type: 'warning', message: '请选择开启时间' });
      }
      let params = {
        gimpUserId: this.userInfo.data.id,
        adInfos: [
          {
            id:
              this.level == 'campaign'
                ? this.data.ad.campaignId
                : this.level == 'adset'
                ? this.data.ad.adGroupId
                : this.data.ad.adId,
            level: this.level,
            platform: this.data.ad.platform,
            adAccountId: this.data.ad.adAccountId,
            thirdUserId: this.data.ad.credentialId && this.data.ad.credentialId.toString(),
          },
        ],
        handleTime: `${this.form.handleDate} ${this.form.taskType}:00`,
      };
      this.$showLoading();
      settingAdScheduleOpenTaskMobile(params).then(
        (res) => {
          this.$hideLoading();
          if (res.code == 0) {
            Notify({ type: 'success', message: '定时开启已打开！' });
            this.$emit('settingComplete', this.form, 1);
            this.onCancel();
          } else {
            // Notify({ type: "danger", message: res.comment });
          }
        },
        (err) => {
          this.$hideLoading();
          console.log(err);
        },
      );
    },
  },
};
</script>
<style lang="scss" scoped></style>
